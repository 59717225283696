import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as turf from '@turf/turf';
import * as ss from 'simple-statistics';
import { getAircraftIcon } from './aircraft-icons';
mapboxgl.accessToken = 'pk.eyJ1IjoibGVvbmFzamFua2F1c2thcyIsImEiOiJjbTF5NzhlemoxMzk3Mmtzamg2MTFtZ3lrIn0.fQXo1HnhyAVVeiyzk_H7-A';

// Configurable marker URLs
const DEPARTURE_ICON_URL = '/assets/images/map/map_marker_destination.png';
const ARRIVAL_ICON_URL = '/assets/images/map/map_marker_arrival.png';
const CURRENT_LOCATION_ICON_URL = '/assets/images/map/2024/marker_location.png';

const LON_THRESHOLD = 180;

class MapController {
    constructor(container, config) {
        this.container = container;
        this.config = config;
        this.markers = [];
        this.airports = [];
        this.polyline = [];
        this.processedPolyline = [];
        this.pilotPolyline = [];
        this.companyPolyline = [];
        this.flightData = [];
        this.selectedPlaneKey = '';
        this.segmentPopups = [];
        this.flightDataInterval;
        this.selectedFlightDataInterval;
        this.bookFlightMapData = {};
        this.standMapData = {};
        this.bookingMapData = {};
        this.allAvailableDestinations = {};
        this.allAvailableSelectedDestinations = {};
        this.selectedDeparture = {};
        this.selectedArrival = {};
        this.wireObject;
        this.fullScreenControl;
        this.initializeMap();
        this.getSidebarElement();
        this.addThemeChangeListener();
        this.startResizeObserver();
        this.hasBookingsToSelf = false;
    }

    getSidebarElement() {
        if (this.config && (this.config.flightmap || this.config.bookFlightMap)) {
            this.sidebar = document.getElementById('sidebar');
        }
    }

    async initializeMap() {
        const theme = this.getTheme();
        const styleUrl = this.getMapStyleUrl(theme);

        if (this.map) {
            this.map.remove();
        }

        this.map = new mapboxgl.Map({
            container: this.container,
            style: styleUrl,
            center: [0, 0],
            zoom: 2,
            dragRotate: false,
            attributionControl: false,
            logoPosition: 'top-right',
            projection: 'globe',
            testMode: true
        });

        this.map.on('style.load', () => {
            this.applyMapTheme(theme);
            if (this.polyline.length > 0) {
                this.renderPolyline(this.polyline);
            }
            if (this.airports && Object.keys(this.airports).length > 0) {
                this.renderAirports(this.airports);
            }
            if (this.pilotPolyline.length > 0 || this.companyPolyline.length > 0) {
                this.renderRoutePolylines(this.pilotPolyline, this.companyPolyline);
            }

            if (Object.keys(this.flightData).length > 0) {
                for (let key in this.markers) {
                    this.markers[key].remove();
                }
                this.markers = [];

                this.dealWithPlaneMarkers(true);
            }

            if (Object.keys(this.bookFlightMapData).length > 0 && !this.isDestinationMap) {
                this.dealWithDestinationMarkers();
            }

            if (this.isDestinationMap) {
                this.getDestinationMapData(this.wireObject);
            }

            if(this.isStandMap) {
                this.getStandMapData(this.wireObject);
            }
        });

        if (this.config.flightmap) {
            this.startFlightDataInterval();
        }
    }

    startResizeObserver() {
        const resizeObserver = new ResizeObserver(() => this.resizeMap());
        resizeObserver.observe(document.getElementById(this.container));
    }

    resizeMap() {
        this.map.resize();
    }

    getTheme() {
        return document.documentElement.classList.contains('dark') ? 'dark' : 'light';
    }

    getMapStyleUrl(theme) {
        return theme === 'dark' ? '/assets/maps/vamsys-dark.json' : '/assets/maps/vamsys-light.json';
    }

    applyMapTheme(theme) {
        if (this.sidebar) {
            if(! this.config.bookFlightMap){
                this.closeSidebar();
            }
        }

        const fogSettings = theme === 'dark' ? {
            color: 'rgb(0, 0, 0)',
            'high-color': 'rgb(70, 70, 70)',
            'horizon-blend': 0.5,
            'space-color': 'rgb(11, 11, 25)',
            'star-intensity': 0.1
        } : {
            color: 'rgb(255, 255, 255)',
            'high-color': 'rgb(200, 200, 200)',
            'horizon-blend': 0.5,
            'space-color': 'rgb(240, 240, 255)',
            'star-intensity': 0.0
        };
        this.map.setFog(fogSettings);

        const fillColor = this.getLineColor(theme);
        if (this.map.getLayer('pilot-flight-path-layer')) {
            this.map.setPaintProperty('pilot-flight-path-layer', 'line-color', fillColor);
        }

        if (this.map.getLayer('company-flight-path-layer')) {
            this.map.setPaintProperty('company-flight-path-layer', 'line-color', fillColor);
        }
    }

    getLineColor(theme) {
        return theme === 'dark' ? '#fff' : '#000';
    }

    addThemeChangeListener() {
        window.addEventListener('theme-changed', () => this.initializeMap());
    }

    renderRoutePolylines(pilotRouteLine, companyRouteLine) {
        const theme = this.getTheme();
        const lineColor = this.getLineColor(theme);

        if (pilotRouteLine && pilotRouteLine.length > 0) {
            this.pilotPolyline = this.splitAntimeridian(Object.values(pilotRouteLine));
            const line = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: this.pilotPolyline.map(point => [point.longitude, point.latitude])
                },
                properties: {}
            };

            if (this.map.getSource('pilot-flight-path')) {
                this.map.getSource('pilot-flight-path').setData(line);
            } else {
                this.map.addSource('pilot-flight-path', {
                    type: 'geojson',
                    data: line,
                    lineMetrics: true
                });

                this.map.addLayer({
                    id: 'pilot-flight-path-layer',
                    type: 'line',
                    source: 'pilot-flight-path',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        'line-color': lineColor,
                        'line-opacity': 0.4,
                        'line-width': 4
                    }
                });
            }
        }

        if (companyRouteLine && companyRouteLine.length > 0) {
            this.companyPolyline = this.splitAntimeridian(Object.values(companyRouteLine));
            const line = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: this.companyPolyline.map(point => [point.longitude, point.latitude])
                },
                properties: {}
            };

            if (this.map.getSource('company-flight-path')) {
                this.map.getSource('company-flight-path').setData(line);
            } else {
                this.map.addSource('company-flight-path', {
                    type: 'geojson',
                    data: line,
                    lineMetrics: true
                });

                if (this.map.getLayer('flight-path-layer')) {
                    this.map.addLayer({
                        id: 'company-flight-path-layer',
                        type: 'line',
                        source: 'company-flight-path',
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            'line-color': lineColor,
                            'line-opacity': 0.4,
                            'line-width': 4
                        }
                    }, 'flight-path-layer');
                } else {
                    this.map.addLayer({
                        id: 'company-flight-path-layer',
                        type: 'line',
                        source: 'company-flight-path',
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            'line-color': lineColor,
                            'line-opacity': 0.4,
                            'line-width': 4
                        }
                    });
                }

            }
        }
    }

    renderPolyline(polyline, updateBounds = true, interactWithPolyline = true) {
        this.clearLayersAndAll();
        let processedPolyline;
        let line;
        let gradientStops;
        if (interactWithPolyline) {
            if (!polyline || polyline.length === 0) return;
            this.polyline = polyline;
            processedPolyline = this.splitAntimeridian(Object.values(polyline));
            this.processedPolyline = processedPolyline;
            ({ line, gradientStops } = this.createGradientLine(processedPolyline)); // Use destructuring assignment without 'let'
        } else {
            let processedPolyline = this.splitAntimeridian(Object.values(polyline));
            ({ line, gradientStops } = this.createGradientLine(processedPolyline)); // Use destructuring assignment without 'let'
        }

        this.addPolylineSegmentPopups(polyline);

        this.map.addSource('flight-path', {
            type: 'geojson',
            data: line,
            lineMetrics: true
        });

        this.map.addLayer({
            id: 'flight-path-layer',
            type: 'line',
            source: 'flight-path',
            layout: {
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'line-width': 4,
                'line-gradient': [
                    'interpolate', ['linear'], ['line-progress'],
                    ...gradientStops.flat()
                ]
            },
            before: this.map.getStyle().layers[this.map.getStyle().layers.length - 1].id
        });

        if (updateBounds) {
            this.fitMapToBounds();
        }
    }

    addPolylineSegmentPopups(line) {
        this.segmentPopups.forEach(function(popup) {
            popup.remove();
        });
        // Clear the popups array
        this.segmentPopups = [];

        const geojson = {
            type: 'FeatureCollection',
            features: []
        };

        function toDMS(deg, isLat) {
            const absolute = Math.abs(deg);
            const degrees = Math.floor(absolute);
            const minutes = Math.floor((absolute - degrees) * 60);
            const seconds = ((absolute - degrees - (minutes / 60)) * 3600).toFixed(2);
            const direction = isLat ? (deg >= 0 ? 'N' : 'S') : (deg >= 0 ? 'E' : 'W');

            return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
        }

        function formatCoordinates(lat, lon) {
            return `${toDMS(lat, true)}, ${toDMS(lon, false)}`;
        }

        let keys;
        if (typeof line === 'object') {
            keys = Object.keys(line).map(Number);
        } else {
            keys = line;
        }

        for (let i = 0; i < keys.length - 1; i++) {
            let point;
            let nextPoint;
            if (typeof line === 'object') {
                const currentKey = keys[i];
                const nextKey = keys[i + 1];

                // Access the current and next lines
                point = line[currentKey];
                nextPoint = line[nextKey];
            } else {
                point = line[i];
                nextPoint = line[i + 1];
            }

            const groundspeedUnit = point.groundspeed === 1 ? 'kt' : 'kts';
            geojson.features.push({
                type: 'Feature',
                properties: {
                    title: formatCoordinates(point.latitude, point.longitude), // Format coordinates for title
                    description: JSON.stringify({
                        altitude: `${point.altitude} ft`,
                        magnetic_heading: `${point.magnetic_heading}°`,
                        groundspeed: `${point.groundspeed} ${groundspeedUnit}`,
                        time: point.time
                    })
                },
                geometry: {
                    type: 'LineString',
                    coordinates: [
                        [point.longitude, point.latitude],
                        [nextPoint.longitude, nextPoint.latitude]
                    ]
                }
            });
        }

        if (this.map.getLayer('polyline-segments-layer')) {
            this.map.removeLayer('polyline-segments-layer');
        }

        // Check if the source exists
        if (this.map.getSource('polyline-segments')) {
            this.map.removeSource('polyline-segments');
        }

        this.map.addSource('polyline-segments', { type: 'geojson', data: geojson });

        this.map.addLayer({
            id: 'polyline-segments-layer',
            type: 'line',
            source: 'polyline-segments',
            layout: {},
            paint: {
                'line-color': '#888',
                'line-width': 20,
                'line-opacity': 0  // Makes the segments invisible
            }
        });

        let currentFeatureId = null;
        const popup = new mapboxgl.Popup({
            closeButton: false,
            className: 'map-airport-popup'
        });

        this.segmentPopups.push(popup);

        this.map.on('mousemove', 'polyline-segments-layer', (e) => {
            this.map.getCanvas().style.cursor = 'pointer';

            const feature = e.features[0];
            const coordinates = e.lngLat;
            const { title, description } = feature.properties;

            if (currentFeatureId !== feature.id) {
                const popupContent = this.createPopupContent(title, description);
                popup.setLngLat(coordinates).setHTML(popupContent).addTo(this.map);

                currentFeatureId = feature.id;
            } else {
                // Update the position of the popup if it is the same feature
                popup.setLngLat(coordinates);
            }
            // Always set the content in case it needs to update
            popup.setHTML(this.createPopupContent(title, description));
        });

        this.map.on('mouseleave', 'polyline-segments-layer', () => {
            this.map.getCanvas().style.cursor = '';
            popup.remove();
            currentFeatureId = null;
        });
    }

// Helper function to create popup content HTML using Tailwind CSS
    createPopupContent(title, description) {
        // Ensure description is parsed if it is a JSON string
        let desc = description;
        if (typeof description === 'string') {
            try {
                desc = JSON.parse(description);
            } catch (error) {
                console.error('Error parsing description JSON:', error);
            }
        }

        return `<div class="p-2">
                <div class="font-semibold mb-1">${title}</div>
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <div class="font-semibold">Altitude:</div>
                        <div>${desc.altitude}</div>
                    </div>
                    <div>
                        <div class="font-semibold">Heading:</div>
                        <div>${desc.magnetic_heading}</div>
                    </div>
                    <div>
                        <div class="font-semibold">Groundspeed:</div>
                        <div>${desc.groundspeed}</div>
                    </div>
                    <div>
                        <div class="font-semibold">Time:</div>
                        <div>${desc.time}</div>
                    </div>
                </div>
            </div>`;
    }

    fitMapToBounds() {
        let coordinates = this.processedPolyline.map(point => [parseFloat(point.longitude), parseFloat(point.latitude)]);

        if (this.airports) {
            coordinates = coordinates.concat(this.getAirportCoordinates('departure'));
            coordinates = coordinates.concat(this.getAirportCoordinates('arrival'));
        }

        if (this.bookingMapData.userRoute) {
            for (let key in this.bookingMapData.userRoute) {
                let routeItem = this.bookingMapData.userRoute[key];
                coordinates.push([parseFloat(routeItem.longitude), parseFloat(routeItem.latitude)]);
            }
        }
        if (this.bookingMapData.companyRoute) {
            for (let key in this.bookingMapData.companyRoute) {
                let routeItem = this.bookingMapData.companyRoute[key];
                coordinates.push([parseFloat(routeItem.longitude), parseFloat(routeItem.latitude)]);
            }
        }
        this.normalizeCoordinates(coordinates);

        if (coordinates.length > 0) {
            const lineString = turf.lineString(coordinates);
            const bbox = turf.bbox(lineString);
            this.map.fitBounds(bbox, { padding: 50 });
        }

        let loader = document.getElementById('mapSpinner');
        loader.classList.add('hidden');
    }

    getAirportCoordinates(type) {
        const coordinates = [];
        const airportArray = Array.isArray(this.airports[type]) ? this.airports[type] : [this.airports[type]];

        airportArray.forEach(airport => {
            if (airport && airport.lon !== undefined && airport.lat !== undefined) {
                coordinates.push([parseFloat(airport.lon), parseFloat(airport.lat)]);
            }
        });

        return coordinates;
    }

    normalizeCoordinates(coords) {
        for (let i = 1; i < coords.length; i++) {
            const prevLon = coords[i - 1][0];
            let currLon = coords[i][0];

            if (Math.abs(currLon - prevLon) > LON_THRESHOLD) {
                currLon += (currLon > prevLon) ? -360 : 360;
            }
            coords[i][0] = currLon;
        }
    }

    splitAntimeridian(path) {
        if (!path) return path;

        if(Object.keys(path).length == 2) {
            let circlePath = turf.greatCircle(turf.point([path[0].longitude, path[0].latitude]), turf.point([path[1].longitude, path[1].latitude]));
            let tempPath = [];
            if(circlePath.geometry.type == 'MultiLineString') {
                for (let key in circlePath.geometry.coordinates[0]) {
                    tempPath.push({longitude: +circlePath.geometry.coordinates[0][key][0], latitude: +circlePath.geometry.coordinates[0][key][1] });
                }
                for (let key in circlePath.geometry.coordinates[1]) {
                    tempPath.push({longitude: +circlePath.geometry.coordinates[1][key][0], latitude: +circlePath.geometry.coordinates[1][key][1] });
                }
            } else {
                for (let key in circlePath.geometry.coordinates) {
                    tempPath.push({longitude: +circlePath.geometry.coordinates[key][0], latitude: +circlePath.geometry.coordinates[key][1] });
                }
            }
            path = tempPath;
        }

        const newPath = [];
        let accumulatedShift = 0;
        newPath.push(path[0]);
        for (let i = 1; i < path.length; i++) {
            const previousPoint = path[i - 1];
            const currentPoint = path[i];
            let shift = 0;

            const delta = currentPoint.longitude - previousPoint.longitude;
            if (delta > 180) {
                shift = -360;
            } else if (delta < -180) {
                shift = 360;
            }

            accumulatedShift += shift;
            newPath.push({ ...currentPoint, longitude: currentPoint.longitude + accumulatedShift });
        }

        return newPath;
    }

    createGradientLine(path) {
        const totalLength = this.calculatePathLength(path);
        const distances = this.calculateDistancesAlongPath(path);

        const line = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: path.map(point => [point.longitude, point.latitude])
            },
            properties: {}
        };

        const stops = path.map((point, index) => {
            const ratio = distances[index] / totalLength;
            const color = this.getAltitudeColor(point.altitude);
            return [ratio, color];
        }).sort((a, b) => a[0] - b[0]);

        const uniqueStops = this.ensureUniqueStops(stops);

        return { line, gradientStops: uniqueStops };
    }

    ensureUniqueStops(stops) {
        const uniqueStops = [];
        let lastRatio = -1;

        for (const [ratio, color] of stops) {
            if (ratio > lastRatio) {
                uniqueStops.push([ratio, color]);
                lastRatio = ratio;
            }
        }

        return uniqueStops;
    }

    calculatePathLength(path) {
        return path.reduce((totalLength, point, index) => {
            if (index < path.length - 1) {
                const nextPoint = path[index + 1];
                return totalLength + turf.distance([point.longitude, point.latitude], [nextPoint.longitude, nextPoint.latitude], { units: 'kilometers' });
            }
            return totalLength;
        }, 0);
    }

    calculateDistancesAlongPath(path) {
        let totalDistance = 0;
        return path.map((point, index) => {
            if (index > 0) {
                const prevPoint = path[index - 1];
                totalDistance += turf.distance([prevPoint.longitude, prevPoint.latitude], [point.longitude, point.latitude], { units: 'kilometers' });
            }
            return totalDistance;
        });
    }

    getAltitudeColor(altitude) {
        const colorScale = [
            [0.0, 'rgb(255,255,255)'],
            [0.005, 'rgb(255,224,98)'],
            [0.011, 'rgb(255,234,0)'],
            [0.016, 'rgb(240,255,0)'],
            [0.022, 'rgb(204,255,0)'],
            [0.033, 'rgb(66,255,0)'],
            [0.044, 'rgb(30,255,0)'],
            [0.066, 'rgb(0,255,12)'],
            [0.082, 'rgb(0,255,114)'],
            [0.137, 'rgb(0,255,210)'],
            [0.191, 'rgb(0,234,255)'],
            [0.246, 'rgb(0,168,255)'],
            [0.355, 'rgb(0,120,255)'],
            [0.464, 'rgb(0,30,255)'],
            [0.519, 'rgb(78,0,255)'],
            [0.574, 'rgb(96,0,255)'],
            [0.628, 'rgb(216,0,255)'],
            [1.0, 'rgb(255,0,0)']
        ];

        const maxAltitude = 60000;
        const normalizedAltitude = Math.min(Math.max(altitude / maxAltitude, 0), 1);

        for (let i = 0; i < colorScale.length - 1; i++) {
            const [scale1, color1] = colorScale[i];
            const [scale2, color2] = colorScale[i + 1];
            if (normalizedAltitude >= scale1 && normalizedAltitude <= scale2) {
                return this.interpolateBetweenColors(color1, color2, (normalizedAltitude - scale1) / (scale2 - scale1));
            }
        }
        return colorScale[colorScale.length - 1][1];
    }

    interpolateBetweenColors(color1, color2, t) {
        const [r1, g1, b1] = this.parseRgbColor(color1);
        const [r2, g2, b2] = this.parseRgbColor(color2);

        const r = Math.round(r1 + t * (r2 - r1));
        const g = Math.round(g1 + t * (g2 - g1));
        const b = Math.round(b1 + t * (b2 - b1));

        return `rgb(${r},${g},${b})`;
    }

    parseRgbColor(color) {
        const match = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        return match ? [parseInt(match[1]), parseInt(match[2]), parseInt(match[3])] : [0, 0, 0];
    }

    renderAirports(airports) {
        this.airports = airports;
        if (!airports) {
            console.error('No airports data provided.');
            return;
        }

        this.renderAirportMarkers('departure', {
            icon: DEPARTURE_ICON_URL,
            height: 25,
            radius: 17,
            offset: 25,
            id: 'departure',
            anchor: 'bottom'
        });
        this.renderAirportMarkers('arrival', {
            icon: ARRIVAL_ICON_URL,
            height: 25,
            radius: 17,
            offset: 25,
            id: 'arrival',
            anchor: 'bottom'
        });

        this.fitMapToBounds();
    }

    renderAirportMarkers(type, options) {
        const airportArray = Array.isArray(this.airports[type]) ? this.airports[type] : [this.airports[type]];

        airportArray.forEach(airport => {
            if (airport && airport.lon !== undefined && airport.lat !== undefined) {
                const { lon: longitude, lat: latitude, name: title, identifiers: description } = airport;
                this.addMarker(longitude, latitude, title, description, options);
            } else {
                console.error(`Invalid ${type} airport data:`, airport);
            }
        });
    }

    async addPlaneMarker(lng, lat, title, description, options) {
        const markerElement = this.createMarkerElement(options);

        // Wait for the background image to be set if color modification is needed
        if (options.color && options.icon.endsWith('.svg')) {
            await new Promise((resolve) => {
                const checkBackgroundImage = () => {
                    if (markerElement.style.backgroundImage && markerElement.style.backgroundImage !== 'none') {
                        resolve();
                    } else {
                        requestAnimationFrame(checkBackgroundImage);
                    }
                };
                checkBackgroundImage();
            });
        }

        let marker = new mapboxgl.Marker({ element: markerElement, anchor: options.anchor })
            .setLngLat([lng, lat])
            .addTo(this.map);

        if (options.rotation) {
            marker.setRotation(options.rotation);
        }

        const popup = this.createPopup(title, description, options);

        let mouseEnterHandler = () => popup.addTo(this.map).setLngLat(marker.getLngLat());
        let mouseLeaveHandler = () => popup.remove();

        marker.getElement().removeEventListener('mouseenter', mouseEnterHandler);
        marker.getElement().removeEventListener('mouseleave', mouseLeaveHandler);

        marker.getElement().addEventListener('mouseenter', mouseEnterHandler);
        marker.getElement().addEventListener('mouseleave', mouseLeaveHandler);

        this.markers[options.id] = marker;

        return marker;
    }

    addMarker(lng, lat, title, description, options) {
        const markerElement = this.createMarkerElement(options);

        let marker;
        marker = new mapboxgl.Marker({ element: markerElement, anchor: options.anchor })
            .setLngLat([lng, lat])
            .addTo(this.map);

        if (options.rotation) {
            marker.setRotation(options.rotation);
        }

        const popup = this.createPopup(title, description, options);

        let mouseEnterHandler = () => popup.addTo(this.map).setLngLat(marker.getLngLat());
        let mouseLeaveHandler = () => popup.remove();


        marker.getElement().removeEventListener('mouseenter', mouseEnterHandler);
        marker.getElement().removeEventListener('mouseleave', mouseLeaveHandler);

        marker.getElement().addEventListener('mouseenter', mouseEnterHandler);
        marker.getElement().addEventListener('mouseleave', mouseLeaveHandler);

        this.markers[options.id] = marker;

        return marker;
    }

    createMarkerElement(options) {
        const el = document.createElement('div');
        el.style.width = `${options.radius}px`;
        el.style.height = `${options.height}px`;
        el.style.backgroundSize = 'contain';
        el.style.backgroundRepeat = 'no-repeat';

        // Check if options.color is specified and options.icon is an SVG
        if (options.color && options.icon.endsWith('.svg')) {
            // Since fetching is asynchronous, we'll handle it separately
            fetch(options.icon)
                .then(response => response.text())
                .then(svgText => {
                    // Modify the fill color in the SVG text
                    const coloredSvgText = svgText.replace(/fill="[^"]*"/g, `fill="${options.color}"`);

                    // Create a Blob from the SVG text
                    const svgBlob = new Blob([coloredSvgText], { type: 'image/svg+xml;charset=utf-8' });

                    // Generate a URL for the Blob
                    const url = URL.createObjectURL(svgBlob);

                    // Set the background image to the modified SVG
                    el.style.backgroundImage = `url(${url})`;
                })
                .catch(error => {
                    console.error('Error loading SVG:', error);
                    // Fallback to the original icon
                    el.style.backgroundImage = `url(${options.icon})`;
                });
        } else {
            // Use the icon as is
            el.style.backgroundImage = `url(${options.icon})`;
        }

        return el;
    }

    createPopup(title, description, options) {
        const markerHeight = options.height;
        const markerRadius = options.radius;
        const linearOffset = options.offset;
        const popupOffsets = {
            'top': [0, 0],
            'top-left': [0, 0],
            'top-right': [0, 0],
            'bottom': [0, -markerHeight],
            'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
            'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
            'left': [markerRadius, (markerHeight - markerRadius) * -1],
            'right': [-markerRadius, (markerHeight - markerRadius) * -1]
        };
        const popupContent = `
            <div>
                <div class="title">${title}</div>
                <div class="description">${description}</div>
            </div>
        `;
        const popupElement = document.createElement('div');
        popupElement.innerHTML = popupContent;
        return new mapboxgl.Popup({
            offset: popupOffsets,
            closeButton: false,
            className: 'map-airport-popup'
        }).setDOMContent(popupElement);
    }


    startFlightDataInterval() {
        if (!this.flightDataInterval) {
            this.flightDataInterval = setInterval(() => {
                this.getFlightMapData(false);
            }, 60000); // Execute every minute
        }
    }

    stopFlightDataInterval() {
        if (this.flightDataInterval) {
            clearInterval(this.flightDataInterval);
            this.flightDataInterval = null;
        }
    }

    startSelectedFlightDataInterval() {
        if (!this.selectedFlightDataInterval) {
            this.selectedFlightDataInterval = setInterval(() => {
                this.getSelectedFlightData(false);
            }, 20000); // Execute every minute
        }
    }

    stopSelectedFlightDataInterval() {
        if (this.selectedFlightDataInterval) {
            clearInterval(this.selectedFlightDataInterval);
            this.selectedFlightDataInterval = null;

            if (this.selectedFlightCancelSource) {
                this.selectedFlightCancelSource.cancel('Operation cancelled by user.'); // Cancel the request
                this.selectedFlightCancelSource = null;
            }
        }
    }

    getFlightMapData(updateBounds = true) {
        return axios.get('/api/data/mapdata').then((response) => {
            this.flightData = response.data;
            this.dealWithPlaneMarkers(updateBounds);
        });
    }

    getSelectedFlightData(updateBounds = false) {
        this.selectedFlightCancelSource = axios.CancelToken.source();

        return axios.get('/api/data/mapdata/' + this.selectedPlaneKey, {
            cancelToken: this.selectedFlightCancelSource.token // Pass the cancel token to the request
        }).then((response) => {
            this.flightData[this.selectedPlaneKey] = response.data;
            this.updatePlaneMarker(this.selectedPlaneKey);
        });
    }

    async dealWithPlaneMarkers(updateBounds) {
        this.removePlaneMarkers();
        const createPromises = []; // Array to hold promises from createPlaneMarker

        for (let key in this.flightData) {
            if (this.markers[key]) {
                if (this.selectedPlaneKey && this.selectedPlaneKey == key) {
                    // Do nothing, as it's handled elsewhere
                } else {
                    this.updatePlaneMarker(key);
                }
            } else {
                // Collect the promise returned by createPlaneMarker
                const createPromise = this.createPlaneMarker(key);
                if (createPromise instanceof Promise) {
                    createPromises.push(createPromise);
                }
            }
        }

        // Wait for all createPlaneMarker promises to resolve
        if (createPromises.length > 0) {
            await Promise.all(createPromises);
        }

        if (updateBounds) {
            this.fitMapToMarkerBounds();
        }
    }

    removePlaneMarkers() {
        for (let key in this.markers) {
            if (!(key in this.flightData)) {
                if (this.selectedPlaneKey && this.selectedPlaneKey == key) {
                    this.closeSidebar();
                }
                this.markers[key].remove();
                delete this.markers[key];
            }
        }
    }

    updatePlaneMarker(key) {
        let marker = this.markers[key];
        let booking = this.flightData[key];

        marker.setLngLat([booking.progress.location.lon, booking.progress.location.lat]);
        marker.setRotation(booking.progress.magneticHeading);

        if (this.selectedPlaneKey && this.selectedPlaneKey == key) {
            this.doPlaneMarkerClickEvent(key, true);
        }

    }

    async createPlaneMarker(key) {
        let booking = this.flightData[key];

        // Determine title and description based on booking data
        let title, description;
        if (booking.pilot.rank.abbreviation) {
            title = `${booking.user.name}, ${booking.pilot.rank.abbreviation} - ${booking.pilot.username}`;
            description = `<p>${booking.departureAirport.identifiers} to ${booking.arrivalAirport.identifiers}</p>`;
        } else {
            title = `${booking.user.name} - ${booking.pilot.username}`;
            description = `<p>${booking.departureAirport.identifiers} to ${booking.arrivalAirport.identifiers}</p>`;
        }

        let planeIcon = getAircraftIcon(booking.aircraft.code);
        const iconPath = '/assets/images/map/planes/' + planeIcon.icon + '.svg';
        let desiredColor = this.getTheme() === 'dark' ? this.config.markerDark : this.config.markerLight;
        desiredColor = '#'+desiredColor;

        // Prepare options, including the color
        const options = {
            icon: iconPath,
            height: 26,
            radius: 26,
            offset: 26,
            rotation: booking.progress.magneticHeading,
            id: booking.bookingId,
            anchor: 'center',
            color: desiredColor // Pass the desired color here
        };

        // Call addMarker, which uses createMarkerElement internally
        const marker = await this.addPlaneMarker(
            booking.progress.location.lon,
            booking.progress.location.lat,
            title,
            description,
            options
        );

        this.addPlaneMarkerClickEvent(marker, key);
    }

    createColoredMarkerElement(iconPath, color) {
        const markerEl = document.createElement('div');
        markerEl.className = 'marker';

        fetch(iconPath)
            .then(response => response.text())
            .then(svgText => {
                // Modify the SVG to include a CSS class or inline styles
                const coloredSvgText = svgText.replace(
                    '<svg',
                    `<svg style="fill: ${color};"`
                );
                markerEl.innerHTML = coloredSvgText;
            })
            .catch(error => console.error('Error loading SVG:', error));

        return markerEl;
    }

    addPlaneMarkerClickEvent(marker, key) {
        marker.getElement().addEventListener('click', (e) => {
            this.stopSelectedFlightDataInterval();
            this.startSelectedFlightDataInterval();
            this.doPlaneMarkerClickEvent(key, false);
            this.getSelectedFlightData(false);
        });
    }

    doPlaneMarkerClickEvent(key, manualTrigger) {
        let booking = this.flightData[key];
        let marker = this.markers[key];
        this.selectedPlaneKey = key;
        this.renderPolyline(booking.progress.posreps, false, false);
        if (booking.route.routeLines.user_or_company) {
            this.renderRoutePolylines(booking.route.routeLines.user_or_company, []);
        }

        if (!this.config.dashboard) {
            this.openSidebar();
        }
        if (!manualTrigger) {
            this.map.panTo([marker._lngLat.lng, marker._lngLat.lat]);
        }
    }

    openSidebar() {
        function openLink() {
            let selectedBookingObject = this.flightData[this.selectedPlaneKey];
            window.open(selectedBookingObject.aircraft.imageLinkback, '_blank');
        }

        let selectedBookingObject = this.flightData[this.selectedPlaneKey];
        document.getElementById('sidebarPilotName').innerText = selectedBookingObject.pilot.username + ' - ' + selectedBookingObject.user.name;
        document.getElementById('sidebarDepartureName').innerText = selectedBookingObject.departureAirport.name;
        document.getElementById('sidebarArrivalName').innerText = selectedBookingObject.arrivalAirport.name;
        document.getElementById('sidebarDepartureIdentifiers').innerHTML = selectedBookingObject.departureAirport.identifiers + ' <br> ' + new Date(selectedBookingObject.progress.departureTime).toTimeString().slice(0, 5);
        document.getElementById('sidebarArrivalIdentifiers').innerHTML = selectedBookingObject.arrivalAirport.identifiers + ' <br> ' + new Date(selectedBookingObject.progress.estimatedArrivalTime).toTimeString().slice(0, 5);
        document.getElementById('sidebarCallsign').innerText = selectedBookingObject.booking.callsign;
        document.getElementById('sidebarFlightNumber').innerText = selectedBookingObject.booking.flightNumber;
        document.getElementById('sidebarTimeRemaining').innerText = selectedBookingObject.progress.timeRemaining;
        document.getElementById('sidebarDistanceRemaining').innerText = selectedBookingObject.progress.distanceRemaining + ' NM';
        document.getElementById('sidebarHeading').innerText = selectedBookingObject.progress.magneticHeading + '°';
        document.getElementById('sidebarGroundSpeed').innerText = selectedBookingObject.progress.groundSpeed + ' kts';
        document.getElementById('sidebarAircraft').innerText = selectedBookingObject.aircraft.code;
        document.getElementById('sidebarRegistration').innerText = selectedBookingObject.aircraft.registration;
        document.getElementById('sidebarPhase').innerText = selectedBookingObject.progress.currentPhase + ' / ' + Math.round(selectedBookingObject.progress.altitude / 100) * 100 + '  ft / ' + selectedBookingObject.booking.network;

        let sidebarImageAttributionDiv = document.getElementById('sidebarImageAttribution');
        if (selectedBookingObject.aircraft && selectedBookingObject.aircraft.imageAttribution) {
            sidebarImageAttributionDiv.style.display = 'flex'; // or 'block'
            sidebarImageAttributionDiv.innerText = 'Image by ' + selectedBookingObject.aircraft.imageAttribution;
        } else {
            sidebarImageAttributionDiv.style.display = 'none';
        }
        let sidebarTopBackgroundDiv = document.getElementById('sidebarTopBackground');

        this.boundOpenLink = this.boundOpenLink || openLink.bind(this);

        if (selectedBookingObject.aircraft && selectedBookingObject.aircraft.image) {
            sidebarTopBackgroundDiv.style.backgroundImage = `url("${selectedBookingObject.aircraft.image}")`;
            sidebarTopBackgroundDiv.className = 'min-h-[250px] bg-cover bg-center p-6 bg-gradient-to-r from-[#4361ee] to-[#160f6b] cursor-pointer';

            sidebarTopBackgroundDiv.removeEventListener('click', this.boundOpenLink);
            sidebarTopBackgroundDiv.addEventListener('click', this.boundOpenLink);
        }

        this.sidebar.classList.remove('hidden');
    }

    closeSidebar() {
        this.stopSelectedFlightDataInterval();
        this.clearLayersAndAll();
        this.selectedPlaneKey = null;
        this.sidebar.classList.add('hidden');
    }

    clearLayersAndAll() {
        this.segmentPopups.forEach(function(popup) {
            popup.remove();
        });

        if (this.map.getLayer('flight-path-layer')) {
            this.map.removeLayer('flight-path-layer');
        }
        if (this.map.getSource('flight-path')) {
            this.map.removeSource('flight-path');
        }
        if (this.map.getLayer('polyline-segments-layer')) {
            this.map.removeLayer('polyline-segments-layer');
        }
        if (this.map.getSource('polyline-segments')) {
            this.map.removeSource('polyline-segments');
        }

        if (this.map.getLayer('pilot-flight-path-layer')) {
            this.map.removeLayer('pilot-flight-path-layer');
        }

        if (this.map.getSource('pilot-flight-path')) {
            this.map.removeSource('pilot-flight-path');
        }

        if (this.map.getLayer('stands-layer')) {
            this.map.removeLayer('stands-layer');
        }

        if (this.map.getSource('stands-source')) {
            this.map.removeSource('stands-source');
        }

    }

    fitMapToMarkerBounds() {
        var bounds = new mapboxgl.LngLatBounds();

        if (this.config.bookFlightMap) {
            const centerCoords = [this.bookFlightMapData.currentAirport.lon, this.bookFlightMapData.currentAirport.lat];
            this.map.flyTo({ center: centerCoords, zoom: 4 });
        } else {
            if (this.markers.length > 0) {
                this.markers.forEach(function(marker) {
                    bounds.extend(marker.getLngLat());
                });

                this.map.fitBounds(bounds, { maxZoom: 4 });
            }
        }

        let loader = document.getElementById('mapSpinner');
        loader.classList.add('hidden');
    }

    getBookingMapData($wire) {
        this.wireObject = $wire;
        this.wireObject.getData().then(() => {
            this.bookingMapData.airports = $wire.$get('airports');
            this.bookingMapData.userRoute = $wire.$get('userWaypoints');
            this.bookingMapData.companyRoute = $wire.$get('waypoints');
        }).then(() => {
            this.renderAirports(this.bookingMapData.airports);
            this.renderRoutePolylines(this.bookingMapData.companyRoute, this.bookingMapData.userRoute);

            let loader = document.getElementById('mapSpinner');
            loader.classList.add('hidden');
        });
    }

    getBookFlightMapData($wire) {
        this.closeSidebar();
        this.hasBookingsToSelf = false;
        this.wireObject = $wire;
        this.bookFlightMapData.currentAirport = $wire.$get('currentPilotLocation');
        this.bookFlightMapData.destinationAirports = $wire.$get('destinationAirports');
        this.bookFlightMapData.additionalBooking = $wire.$get('additionalBooking');
        this.bookFlightMapData.favoriteAirports = $wire.$get('favoriteAirports');
        this.bookFlightMapData.jumpseatEnabled = $wire.$get('jumpseatEnabled');
        this.bookFlightMapData.jumpseatLocations = $wire.$get('jumpseatLocations');


        this.dealWithDestinationMarkers();
        this.openDestinationSidebarInitial();
    }

    dealWithDestinationMarkers() {
        for (let key in this.markers) {
            this.markers[key].remove();
        }
        this.markers = [];

        this.addJumpseatAirportMarkers();
        this.addCurrentLocationMarker();
        this.addDestinationAirportMarkers();
        this.fitMapToMarkerBounds();
    }

    registerButtonActionsForBookFlightMap() {
        window.doJumpseat = async () => {
            await this.wireObject.jumpseatToLocation(this.selectedPlaneKey);
            this.getBookFlightMapData(this.wireObject);
        };

        window.doBooking = async () => {
            await this.wireObject.createDispatch(this.bookFlightMapData.currentAirport.id, this.selectedPlaneKey);
        };

        window.doBookingSelf = async () => {
            await this.wireObject.createDispatch(this.bookFlightMapData.currentAirport.id, this.bookFlightMapData.currentAirport.id);
        };

        window.doRandomAirport = async () => {
            const keys = Object.keys(this.bookFlightMapData.destinationAirports);
            const randomKey = keys[Math.floor(Math.random() * keys.length)];
            const randomAirport = this.bookFlightMapData.destinationAirports[randomKey];
            this.doDestinationMarkerClickEvent(randomAirport.id, false);
            return true;
        }

        this.wireObject.on('selected-airport', (event) => {
            this.doDestinationMarkerClickEvent(event[0], false);
        });

        this.wireObject.on('selected-country', (event) => {
            this.getBookFlightMapData(this.wireObject);
        });

        this.wireObject.on('start-map-loading', (event) => {
            let loader = document.getElementById('mapSpinner');
            loader.classList.remove('hidden');
            this.wireObject.dispatch('gather-data').then((response) => {
                this.getBookFlightMapData(this.wireObject);
            });
        });
    }

    addCurrentLocationMarker() {
        let airport = this.bookFlightMapData.currentAirport;
        let title = airport.name;
        let description =
            '<p>' +
            airport.identifiers +
            '</p>';
        let options = {
            icon: CURRENT_LOCATION_ICON_URL,
            height: 30,
            radius: 20,
            offset: 20,
            anchor: 'bottom',
            id: airport.id
        };

        let marker = this.addMarker(airport.lon, airport.lat, title, description, options);
    }

    addDestinationMarkerClickEvent(marker, key, isJumpseat) {
        // Clear Sidebar
        // Clear Polylines

        marker.getElement().addEventListener('click', (e) => {
            this.doDestinationMarkerClickEvent(key, isJumpseat);
        });
    }

    doDestinationMarkerClickEvent(key, isJumpseat) {
        if (this.pendingPromise) {
            this.pendingPromise.abort();
            this.pendingPromise = null;
        }

        this.selectedPlaneKey = key;

        this.openDestinationSidebar(isJumpseat);
        this.clearLayersAndAll();
        if (isJumpseat) {
            this.setAdditionalDestinationSidebarData(isJumpseat);
        } else {
            this.renderRoutePolylines(this.bookFlightMapData.destinationAirports[key].routing, []);
            if (this.bookFlightMapData.destinationAirports[key].operators) {
                this.clearLayersAndAll();
                this.renderRoutePolylines(this.bookFlightMapData.destinationAirports[key].routing, []);
                this.setAdditionalDestinationSidebarData(isJumpseat);
            } else {
                this.pendingPromise = this.makeAbortable(
                    this.wireObject.airportRouteQuery(this.bookFlightMapData.currentAirport.id, key)
                );

                this.pendingPromise.promise.then((response) => {
                    this.bookFlightMapData.destinationAirports[key] = {
                        ...this.bookFlightMapData.destinationAirports[key],
                        ...response
                    };
                    this.clearLayersAndAll();
                    this.renderRoutePolylines(this.bookFlightMapData.destinationAirports[key].routing, []);
                    this.setAdditionalDestinationSidebarData(isJumpseat);
                });

                this.pendingPromise.promise.catch((error) => {
                    // Do nothing
                });
            }

        }
    }

    makeAbortable(promise) {
        let abortFn = null;

        const abortablePromise = new Promise((resolve, reject) => {
            abortFn = () => reject(new Error('AbortError'));
            promise.then(resolve, reject);
        });

        return {
            promise: abortablePromise,
            abort() {
                abortFn();
            }
        };
    }

    openDestinationSidebarInitial() {
        let originAirport = this.bookFlightMapData.currentAirport;

        document.getElementById('sidebar-loadingIndicator').style.display = 'none';
        document.getElementById('sidebar-content').style.display = 'block';
        document.getElementById('jumpseat-button').style.display = 'none';
        document.getElementById('sidebar-route-data').style.display = 'none';
        document.getElementById('data-departureAirport').innerHTML = `
            <div class="flex mb-2 items-start justify-between dark:text-white">
                <div>
                    ` + originAirport.name + `
                </div>
                <img class="flex-none" src="https://flagcdn.com/32x24/` + originAirport.countryCode + `.png"
                      srcset="https://flagcdn.com/64x48/` + originAirport.countryCode + `.png 2x, https://flagcdn.com/96x72/` + originAirport.countryCode + `.png 3x"
                      width="20"
                      height="15"/>
            </div>
            <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-center text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">
                ` + originAirport.identifiers + `
            </div>
        `;


        document.getElementById('booking-button').style.display = 'none';
        if(this.hasBookingsToSelf) {
            document.getElementById('booking-self-button').style.display = 'grid';
            document.getElementById('data-buttons').classList = 'grid grid-cols-2 gap-4 px-2 text-center';
        } else {
            document.getElementById('booking-self-button').style.display = 'none';
            document.getElementById('data-buttons').classList = 'grid gap-4 px-2 text-center';
        }

        document.getElementById('data-arrivalAirport').innerHTML = `
            <div class="flex mb-2 items-start justify-between dark:text-white">
                <div>

                </div>
            </div>
            <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-center text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">
                Select Destination
            </div>
        `;

        this.sidebar.classList.remove('hidden');
    }
    openDestinationSidebar(isJumpseat) {
        let originAirport = this.bookFlightMapData.currentAirport;
        let selectedDestination;
        if (isJumpseat) {
            selectedDestination = this.bookFlightMapData.jumpseatLocations[this.selectedPlaneKey];
        } else {
            selectedDestination = this.bookFlightMapData.destinationAirports[this.selectedPlaneKey];

        }

        document.getElementById('sidebar-loadingIndicator').style.display = 'block';
        document.getElementById('sidebar-content').style.display = 'none';
        document.getElementById('jumpseat-button').style.display = 'none';
        document.getElementById('data-departureAirport').innerHTML = `
            <div class="flex mb-2 items-start justify-between dark:text-white">
                <div>
                    ` + originAirport.name + `
                </div>
                <img class="flex-none" src="https://flagcdn.com/32x24/` + originAirport.countryCode + `.png"
                      srcset="https://flagcdn.com/64x48/` + originAirport.countryCode + `.png 2x, https://flagcdn.com/96x72/` + originAirport.countryCode + `.png 3x"
                      width="20"
                      height="15"/>
            </div>
            <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-center text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">
                ` + originAirport.identifiers + `
            </div>
        `;
        if(selectedDestination) {
            document.getElementById('data-arrivalAirport').innerHTML = `
            <div class="flex mb-2 items-start justify-between dark:text-white">
                <div>
                    ` + selectedDestination.name + `
                </div>
                <img class="flex-none" src="https://flagcdn.com/32x24/` + selectedDestination.countryCode + `.png"
                      srcset="https://flagcdn.com/64x48/` + selectedDestination.countryCode + `.png 2x, https://flagcdn.com/96x72/` + selectedDestination.countryCode + `.png 3x"
                      width="20"
                      height="15"/>
            </div>
            <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-center text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">
                ` + selectedDestination.identifiers + `
            </div>
        `;
        }

        this.sidebar.classList.remove('hidden');
    }

    setAdditionalDestinationSidebarData(isJumpseat) {
        let selectedDestination;
        if (isJumpseat) {
            selectedDestination = this.bookFlightMapData.jumpseatLocations[this.selectedPlaneKey];
        } else {
            selectedDestination = this.bookFlightMapData.destinationAirports[this.selectedPlaneKey];
        }
        if (!isJumpseat) { // not jumpseat
            document.getElementById('data-aircraftTypes').innerText = selectedDestination.aircraftTypes;
            document.getElementById('data-operators').innerText = selectedDestination.operators;
            document.getElementById('data-ETE').innerText = selectedDestination.ete;
            document.getElementById('data-distance').innerText = selectedDestination.distance;
            document.getElementById('sidebar-route-data').style.display = 'grid';
        } else {
            document.getElementById('sidebar-route-data').style.display = 'none';
        }

        document.getElementById('sidebar-loadingIndicator').style.display = 'none';
        document.getElementById('sidebar-content').style.display = 'grid';

        if (isJumpseat) { // jumpseat only
            document.getElementById('booking-button').style.display = 'none';
            document.getElementById('booking-self-button').style.display = 'none';
        } else {
            document.getElementById('booking-button').style.display = 'grid';
            document.getElementById('booking-self-button').style.display = 'none';
        }

        if ((selectedDestination.id != this.bookFlightMapData.currentAirport.id) && (isJumpseat || (this.bookFlightMapData.jumpseatEnabled && this.bookFlightMapData.additionalBooking == false))) {
            document.getElementById('jumpseat-button').style.display = 'grid';
            document.getElementById('data-buttons').classList = 'flex justify-around space-x-4 px-2 text-center';
        } else {
            document.getElementById('jumpseat-button').style.display = 'none';
            document.getElementById('data-buttons').classList = 'grid px-2 text-center';
        }

    }

    addDestinationAirportMarkers() {
        for (let key in this.bookFlightMapData.destinationAirports) {
            if (this.bookFlightMapData.currentAirport.id == key) {
                this.addDestinationMarkerClickEvent(this.markers[key], key, false);
                this.hasBookingsToSelf = true;
                continue; // Do not add marker for Current Location;
            }
            let airport = this.bookFlightMapData.destinationAirports[key];
            let icon;
            let height;
            let radius;
            let offset;

            if (airport.base) {
                if (this.bookFlightMapData.favoriteAirports && this.bookFlightMapData.favoriteAirports[key]) {
                    icon = '/assets/images/map/2024/marker_favorite_base.png';
                    height = 30;
                    radius = 20;
                    offset = 20;
                } else {
                    icon = '/assets/images/map/2024/marker_base.png';
                    height = 24;
                    radius = 16;
                    offset = 16;
                }
            } else {
                if (this.bookFlightMapData.favoriteAirports && this.bookFlightMapData.favoriteAirports[key]) {
                    icon = '/assets/images/map/2024/marker_favorite_airport.png';
                    height = 30;
                    radius = 20;
                    offset = 20;
                } else {
                    icon = '/assets/images/map/2024/marker_airport.png';
                    height = 24;
                    radius = 16;
                    offset = 16;
                }
            }

            let title = airport.name;
            let description =
                '<p>' +
                airport.identifiers +
                '</p>';
            let options = {
                icon: icon,
                height: height,
                radius: radius,
                offset: offset,
                anchor: 'bottom',
                id: airport.id
            };

            let marker = this.addMarker(airport.lon, airport.lat, title, description, options);

            this.addDestinationMarkerClickEvent(marker, key, false);
        }
    }

    addJumpseatAirportMarkers() {
        for (let key in this.bookFlightMapData.jumpseatLocations) {

            let airport = this.bookFlightMapData.jumpseatLocations[key];
            let icon;
            let height;
            let radius;
            let offset;

            if (airport.base) {
                if (this.bookFlightMapData.favoriteAirports && this.bookFlightMapData.favoriteAirports[key]) {
                    icon = '/assets/images/map/2024/marker_jumpseat_favorite.png';
                    height = 8;
                    radius = 8;
                    offset = 0;
                } else {
                    icon = '/assets/images/map/2024/marker_jumpseat.png';
                    height = 5;
                    radius = 5;
                    offset = 0;
                }
            } else {
                if (this.bookFlightMapData.favoriteAirports && this.bookFlightMapData.favoriteAirports[key]) {
                    icon = '/assets/images/map/2024/marker_jumpseat_favorite.png';
                    height = 8;
                    radius = 8;
                    offset = 0;
                } else {
                    icon = '/assets/images/map/2024/marker_jumpseat.png';
                    height = 5;
                    radius = 5;
                    offset = 0;
                }
            }

            let title = airport.name;
            let description =
                '<p>' +
                airport.identifiers +
                '</p>';
            let options = {
                icon: icon,
                height: height,
                radius: radius,
                offset: offset,
                anchor: 'bottom',
                id: airport.id
            };

            let marker = this.addMarker(airport.lon, airport.lat, title, description, options);
            this.addDestinationMarkerClickEvent(marker, key, true);
        }
    }

    getStandMapData($wire) {
        this.wireObject = $wire;
        this.isStandMap = true;
        let loader = document.getElementById('mapSpinner');
        loader.classList.remove('hidden');
        this.clearLayersAndAll();
        for (let key in this.markers) {
            this.markers[key].remove();
        }
        this.markers = [];

        this.wireObject.getStands().then((response) => {
            this.standMapData = response;
        }).then(() => {
            this.addStandMarkers();
            // this.addAllDestinationAirportMarkers(this.bookFlightMapData.destinationAirports, false);
            this.fitMapToMarkerBounds();
        });
        this.map.removeControl(this.fullscreenControl, 'top-right');
        if(!this.fullscreenControl) {
            this.fullscreenControl = new mapboxgl.FullscreenControl();
        }
        this.map.addControl(this.fullscreenControl, 'top-right');
    }

    getDestinationMapData($wire) {
        this.wireObject = $wire;
        this.isDestinationMap = true;
        let loader = document.getElementById('mapSpinner');
        loader.classList.remove('hidden');
        this.closeSidebar();
        this.clearLayersAndAll();
        for (let key in this.markers) {
            this.markers[key].remove();
        }
        this.markers = [];
        this.bookingMapData = {};
        this.allAvailableDestinations = {};
        this.allAvailableSelectedDestinations = {};
        this.selectedDeparture = {};
        this.selectedArrival = {};
        this.airportPairData = [];

        document.getElementById('data-arrivalAirport').innerHTML = ``;


        this.wireObject.getAirports().then((response) => {
            this.bookFlightMapData.destinationAirports = response.airports;
            this.bookFlightMapData.currentAirport = response.currentAirport;
            this.bookFlightMapData.jumpseatEnabled = $wire.$get('jumpseatEnabled');
        }).then(() => {
            this.clearLayersAndAll();
            for (let key in this.markers) {
                this.markers[key].remove();
            }
            this.markers = [];
            this.addAllDestinationAirportMarkers(this.bookFlightMapData.destinationAirports, false);
            // this.renderRoutePolylines(this.bookingMapData.companyRoute, this.bookingMapData.userRoute);
            this.fitMapToMarkerBounds();
        });

    }

    addAllDestinationAirportMarkers(data, arrival = false) {
        for (let key in data) {
            let airport = data[key];
            let icon;
            let height;
            let radius;
            let offset;

            if (airport.base) {
                if (this.bookFlightMapData.favoriteAirports && this.bookFlightMapData.favoriteAirports[key]) {
                    icon = '/assets/images/map/2024/marker_favorite_base.png';
                    height = 21;
                    radius = 14;
                    offset = 14;
                } else {
                    icon = '/assets/images/map/2024/marker_base.png';
                    height = 15;
                    radius = 10;
                    offset = 10;
                }
            } else {
                if (this.bookFlightMapData.favoriteAirports && this.bookFlightMapData.favoriteAirports[key]) {
                    icon = '/assets/images/map/2024/marker_favorite_airport.png';
                    height = 21;
                    radius = 14;
                    offset = 14;
                } else {
                    icon = '/assets/images/map/2024/marker_airport.png';
                    height = 15;
                    radius = 10;
                    offset = 10;
                }
            }

            let title = airport.name;
            let description =
                '<p>' +
                airport.identifiers +
                '</p>';
            let options = {
                icon: icon,
                height: height,
                radius: radius,
                offset: offset,
                anchor: 'bottom',
                id: airport.id
            };

            let marker = this.addMarker(airport.lon, airport.lat, title, description, options);

            if(arrival) {
                this.addAllDestinationMarkerPairClickEvent(marker, key, false);
            } else {
                this.addAllDestinationMarkerClickEvent(marker, key, false);
            }
        }
    }

    addAllDestinationMarkerClickEvent(marker, key, isJumpseat) {
        marker.getElement().addEventListener('click', (e) => {
            this.doAllDestinationMarkerClickEvent(key, isJumpseat);
        });
    }

    addAllDestinationMarkerPairClickEvent(marker, key, isJumpseat) {
        marker.getElement().addEventListener('click', (e) => {
            this.doAllDestinationMarkerPairClickEvent(key, isJumpseat);
        });
    }

    doAllDestinationMarkerClickEvent(key, isJumpseat) {
        this.selectedDeparture = this.bookFlightMapData.destinationAirports[key];
        this.setDestinationSidebarInitial();
        this.removeOtherMarkers();
        this.getSelectedAvailableRoutes();
        // Remove all markers, except current one.
        // Get new markers and create them.
        // Set sidebar.
    }

    doAllDestinationMarkerPairClickEvent(key, isJumpseat) {
        this.clearLayersAndAll();
        this.selectedArrival = this.allAvailableSelectedDestinations[key];
        let selectedDestinationAirport = this.selectedArrival;
        document.getElementById('sidebar-loadingIndicator').style.display = 'block';
        document.getElementById('sidebar-content').style.display = 'none';

        document.getElementById('data-arrivalAirport').innerHTML = `
            <div class="flex mb-2 items-start justify-between dark:text-white">
                <div>
                    ` + selectedDestinationAirport.name + `
                </div>
                <img class="flex-none" src="https://flagcdn.com/32x24/` + selectedDestinationAirport.countryCode + `.png"
                      srcset="https://flagcdn.com/64x48/` + selectedDestinationAirport.countryCode + `.png 2x, https://flagcdn.com/96x72/` + selectedDestinationAirport.countryCode + `.png 3x"
                      width="20"
                      height="15"/>
            </div>
            <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-center text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">
                ` + selectedDestinationAirport.identifiers + `
            </div>
        `;
        this.wireObject.airportRouteQuery(this.selectedDeparture.id, this.selectedArrival.id).then((response) => {
            this.airportPairData = response;
        }).then(() => {
            document.getElementById('sidebar-arrival-button').style.display = 'flex';
            document.getElementById('sidebar-arrival-button-text').innerHTML = selectedDestinationAirport.identifiers + '<br/>Airport Information';
            document.getElementById('data-aircraftTypes').innerText = this.airportPairData.aircraftTypes;
            document.getElementById('data-operators').innerText = this.airportPairData.operators;
            document.getElementById('data-ETE').innerText = this.airportPairData.ete;
            document.getElementById('data-distance').innerText = this.airportPairData.distance;

            document.getElementById('sidebar-loadingIndicator').style.display = 'none';
            document.getElementById('sidebar-content-data').style.display = 'grid';
            document.getElementById('sidebar-content').style.display = 'grid';
            this.renderRoutePolylines(this.airportPairData.routing, [])
        });
    }

    setDestinationSidebarInitial() {
        let selectedAirport = this.selectedDeparture;
        document.getElementById('sidebar-jumpseat-button-text').innerHTML = 'Jumpseat to<br/>' + selectedAirport.identifiers;
        document.getElementById('sidebar-departure-button-text').innerHTML = selectedAirport.identifiers + '<br/>Airport Information';

        document.getElementById('sidebar-loadingIndicator').style.display = 'block';
        document.getElementById('sidebar-content').style.display = 'none';
        document.getElementById('sidebar-arrival-button').style.display = 'none';

        document.getElementById('data-departureAirport').innerHTML = `
            <div class="flex mb-2 items-start justify-between dark:text-white">
                <div>
                    ` + selectedAirport.name + `
                </div>
                <img class="flex-none" src="https://flagcdn.com/32x24/` + selectedAirport.countryCode + `.png"
                      srcset="https://flagcdn.com/64x48/` + selectedAirport.countryCode + `.png 2x, https://flagcdn.com/96x72/` + selectedAirport.countryCode + `.png 3x"
                      width="20"
                      height="15"/>
            </div>
            <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-center text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">
            <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-center text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">
                ` + selectedAirport.identifiers + `
            </div>
        `;

        this.sidebar.classList.remove('hidden');
    }

    removeOtherMarkers() {
        for (let key in this.markers) {
            if(key != this.selectedDeparture.id) {
                this.markers[key].remove();
            }
        }
    }

    getSelectedAvailableRoutes() {
        let selectedAirport = this.selectedDeparture;
        this.wireObject.airportSelectedQuery(selectedAirport.id).then((response) => {
            let destinationAirports = response;
            this.allAvailableSelectedDestinations = destinationAirports;
            this.addAllDestinationAirportMarkers(destinationAirports, true);

            document.getElementById('sidebar-loadingIndicator').style.display = 'none';
            document.getElementById('sidebar-content-data').style.display = 'none';
            document.getElementById('sidebar-content').style.display = 'grid';

            if(this.bookFlightMapData.jumpseatEnabled){
                document.getElementById('jumpseat-button').style.display = 'flex';
                document.getElementById('reset-map-button').style.display = 'flex';
            } else {
                document.getElementById('jumpseat-button').style.display = 'none';
                document.getElementById('reset-map-button').style.display = 'grid';
            }
        });
    }

    registerButtonActionsForDestinationMap() {

        window.resetMap = async () => {
            this.getDestinationMapData(this.wireObject);
        }

        window.doJumpseat = async () => {
            await this.wireObject.jumpseatToLocation(this.selectedDeparture.id);
        };

        window.showDepartureData = () => {
            window.open(window.location.origin + "/phoenix/resources/airports/" + this.selectedDeparture.id);
        };

        window.showArrivalData = () => {
            window.open(window.location.origin + "/phoenix/resources/airports/" + this.selectedArrival.id);
        };

        this.wireObject.on('start-map-loading', (event) => {
            this.getDestinationMapData(this.wireObject);
        });
    }

    addStandMarkers() {
        const standFeatures = this.standMapData.map(stand => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [stand.longitude, stand.latitude]
            },
            properties: {
                stand_number: stand.name,
                stand_color: stand.airline_id?'#FFA500':'#FF0000'
            }
        }));

        const geojsonData = {
            type: 'FeatureCollection',
            features: standFeatures
        };

        this.map.addSource('stands-source', {
            type: 'geojson',
            data: geojsonData
        });

        this.map.addLayer({
            id: 'stands-layer',
            type: 'symbol',
            source: 'stands-source',
            layout: {
                'text-field': ['get', 'stand_number'],
                'text-font': ['Noto Sans Medium'],
                'text-size': 12
            },
            paint: {
                'text-color': ['get', 'stand_color'] // Customize the text color
            }
        });

        const bounds = new mapboxgl.LngLatBounds();

        this.standMapData.forEach(stand => {
            bounds.extend([stand.longitude, stand.latitude]);
        });

        // Fit the map to the bounds
        this.map.fitBounds(bounds, {
            padding: 20 // Adjust the padding as needed
        });

        let loader = document.getElementById('mapSpinner');
        loader.classList.add('hidden');
    }



}

export { MapController };
